@import "styles/app.scss";

.errorPage {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-color: $colorLightGray;
    z-index: 0;
    overflow: hidden;

    &_bg_noise {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include bgGrayWithNoise;
    }

    &_ellipse {
        display: none;
    }

    &_leaves {
        position: absolute;
        top: -20vw;
        right: -150vw;
        width: 1030.657px;
        height: 707.255px;
        transform: rotate(11.442deg);
        background-size: cover;
        background-repeat: no-repeat;
    }

    &_leaf {
        display: none;
    }

    .errorInfo {
        text-align: center;
        position: relative;
        z-index: 4;
        padding-top: 140px;
        max-width: 340px;
        margin: 0 auto 40px;

        h1 {
            font-size: 72px;
            line-height: normal;
            margin-bottom: 20px;
        }

        p {
            font-size: 14px;
            color: #848C98;
        }
    }

    .cardsWrapper {
        margin-top: 40px;
        margin-bottom: 40px;
        gap: 16px;
        position: relative;
        z-index: 4;

        @include mediaLg {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
            margin-top: 0;
        }

        @include mediaGt {
            position: relative;
            z-index: 4;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            margin-top: 0;
        }

        @include mediaEx {
            gap: 18px;
        }
        
    }

    .backButton {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 4;
        padding-bottom: 80px;
    }
}


@media (min-width: $screenGt) {
    .errorPage {
        min-height: 810px;
        
        &_ellipse {
            display: block;
            width: 890px;
            height: 890px;
            border-radius: 890px;
            background-color: $colorWhite;
            opacity: 0.7;
            filter: blur(200px);
            position: absolute;
            top: -300px;
            left: -200px;
            z-index: 2;
        }

        &_leaves {
            top: -50px;
            right: -40vw;
            width: 1474.391px;
            height: 1011.753px;
            opacity: 0.7;
        }

        &_leaf {
            display: block;
            position: absolute;
            width: 607px;
            height: 912px;
            top: 450px;
            left: -255px;
            z-index: 2;
            transform: rotate(26deg) rotateY(3.142rad);
    
            @include mediaGt {
                top: 405px;
            }
    
            @include mediaXx {
                width: 733px;
                height: 1102px;
                top: 450px;
                left: -210px;
            }
            svg {
                width: 607px;
                height: 912px;
                @include mediaXx {
                    width: 733px;
                    height: 1102px;
                }
            }
        }
    }
}

@media (min-width: 1441px) {
    .errorPage {
        height: 100vh;
       
    }
}